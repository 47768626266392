import React from 'react';
import { AboutUs } from '../typescript/component';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import { useNavigate } from 'react-router-dom';

export default function AboutUsComponent({ aboutUs }: { aboutUs: AboutUs }) {

  const navigate = useNavigate();
  const { about_the_project, architecture_overview, composable_card, welcome_message, cta } = aboutUs;

  return (
    <>
      <div className="about-us-page max-width">
        <h1 style={{letterSpacing: "1px"}}>{welcome_message}</h1>
            <img src={composable_card?.href} alt={composable_card?.title} style={{
                maxWidth:"100%"
                
            }}/>
            <br />
            <hr className="about-us-page-hr" />
            <button className='about-us-btn' onClick={() => navigate("/hero-form") }>
              {cta}
            </button>
            <hr className="about-us-page-hr" />
            <h2 style={{"textAlign": "center", "marginTop": "20px", marginBottom: "10px"}}>About the Project</h2>
            <div className="about-us-para" dangerouslySetInnerHTML={{__html : `${about_the_project}`}}></div>
            <hr className="about-us-page-hr" />
            <h2 style={{letterSpacing: "1px", marginTop: "10px"}}>Architecture Overview</h2>
            {/*             
                <img 
                style={{ maxWidth:"100%",
                marginBottom: "20px"    
            }} 
                src={architecture_overview?.url} alt={"architecture_overview"}/>  */}
            <InnerImageZoom src={architecture_overview?.url} />
      </div>
    </>
  );
}
