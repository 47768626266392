import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getHeroFormListRes } from "../helper";
import { HeroEntry } from "../typescript/super-hero-form";
import HeroView, { HERO_IMAGE } from "../components/hero-view";



export default function HeroRandomPage() {
  const history = useNavigate();
  const [getEntry, setEntry] = useState({} as HeroEntry);
  const [error, setError] = useState(false);

  async function fetchData() {
    getHeroFormListRes(0, 20, "updated_at")
      .then(result => {
        {
          const rand = Math.floor(Math.random() * result[0].length)
          setEntry(result[0][rand])
        }
      })
      .catch(error => {
        console.error(error);
        setError(true)
      })
  }

  useEffect(() => {
    fetchData();
    error && history("/404");
    const latestHero = setInterval(() => fetchData(), 30000)

    return () => {
      clearInterval(latestHero)
    }
  }, [error]);

  return <HeroView entry={getEntry} type={HERO_IMAGE.COMPOSABLE} latestHeroPage />
}
