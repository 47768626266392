import { HeroEntry, HeroFormFields } from './../typescript/super-hero-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const auth = {
  api_key: `${process.env.REACT_APP_CONTENTSTACK_API_KEY}`,
  authorization: `${process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN}`,
};

const automation_uid = process.env.REACT_APP_CONTENTSTACK_AUTOMATIONS_UID;
const automation_auth = {
  'ah-http-key': `${process.env.REACT_APP_CONTENTSTACK_AUTOMATIONS_API_KEY}`,
};

const parent_uid = `${process.env.REACT_APP_CONTENTSTACK_PARENT_ASSET_UID}`;

const API = process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  ? process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  : process.env.REACT_APP_CONTENTSTACK_API_HOST;

// const API = "api.contentstack.io"
// const APP = "app.contentstack.com"

const APP = process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  ? process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  : process.env.REACT_APP_CONTENTSTACK_APP_HOST;

console.log('API: ', API);
console.log('APP: ', APP);

type HeroAsset = {
  'asset[upload]': File;
  'asset[parent_uid]': string;
  'asset[title]': string;
  'asset[description]': string;
};

type HeroEntryHolder = {
  entry: HeroFormFields;
};

export default {
  async createHero(data: HeroFormFields) {
    const createdAsset = await this.createAsset(data);

    if (createdAsset.status === 201 || createdAsset.status === 200) {
      const entry = {
        ...data,
        title: uuidv4(),
        original_image: createdAsset?.data?.asset?.uid,
      };

      const createdEntry = await this.createEntry('superhero_form', {
        entry: entry,
      });

      // trigger automation
      const uid = createdEntry?.data?.entry?.uid;
      // console.log('trigger auto with ', uid);

      const automation = await this.triggerAutomations(uid);
      return {
        entry: createdEntry?.data?.entry,
        accepted: automation?.data?.__accepted__,
        error: false,
      };
    }
    return { entry: null, accepted: false, error: true };
  },

  createEntry(content_type_uid: string, entry: HeroEntryHolder) {
    return axios.post(`https://${API}/v3/content_types/${content_type_uid}/entries`, entry, {
      headers: { ...auth },
    });
  },

  updateEntry(entry_uid: string, update: HeroEntry, content_type_uid = 'superhero_form') {
    return axios.put(
      `https://${API}/v3/content_types/${content_type_uid}/entries/${entry_uid}`,
      { entry: update },
      {
        headers: { ...auth },
      },
    );
  },

  triggerAutomations(entry_uid: string, content_type_uid = 'superhero_form') {
    return axios.post(
      `https://${APP}/automations-api/run/${automation_uid}`,
      {
        entry_uid: entry_uid,
        content_type_uid: content_type_uid,
      },
      {
        headers: { ...automation_auth, 'Content-Type': 'application/json' },
      },
    );
  },

  createAsset(asset: HeroFormFields) {
    const img: HeroAsset = {
      'asset[upload]': asset?.original_image?.[0],
      'asset[parent_uid]': parent_uid,
      'asset[title]': `hero-${asset?.title}`,
      'asset[description]': '',
    };

    const formData: FormData = new FormData();
    Object.entries(img).map(([key, value]) => formData.append(key, value));

    console.log('to save asset: ', img);

    return axios.post(`https://${API}/v3/assets`, formData, {
      headers: {
        ...auth,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
    });
  },
};
