import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getHeroFormListRes, getHeroRandom, getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { Prop, Entry, ImageData } from "../typescript/pages";
import useLocalStorage from "use-local-storage";

// import optional lightbox plugins
import { PhotoAlbum, RenderPhoto, Photo } from "react-photo-album";
import Loader from "../components/atoms/loader";
import { HeroEntry } from "../typescript/super-hero-form";

interface HeroImagePhoto extends Photo {
  job_title: string;
  name: string;
  entry: HeroEntry
}

export default function HeroGallery({ entry }: Prop) {
  const history = useNavigate();
  const [getEntry, setEntry] = useState({} as Entry);
  const [getList, setList] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  let skipData: any = window.localStorage.getItem("skip");
  if (skipData === null) {
    skipData = 0;
  }
  const [skip, setSkip] = useLocalStorage("skip", skipData);
  const limit = 20;

  const navigateToHero = (uid: any) => {
    history('/hero-card', { state: { entry: uid } })
    return
  }

  const renderPhoto: RenderPhoto<HeroImagePhoto> = ({ photo, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
    <div
      className="hero-gallery-container"
      style={{
        width: style?.width,
        padding: `${layoutOptions.padding - 2}px`,
      }}
      onClick={() => navigateToHero(photo.entry)}
    >
      <img alt={alt} className="rounded" style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
      <div className="hero-gallery-title">
        {photo.title}
      </div>
    </div>
  );


  async function fetchData() {
    try {
      !loading && setLoading(true)
      const heroPage = await getPageRes("/gallery");
      const result = await getHeroRandom(skip as number, limit);
      (!heroPage || !result) && setError(true);
      setTotalCount(result[1]);
      // const archive = [] as any; skipData: 
      const heroList = [] as any;

      result[0]
        .filter((image: ImageData) => !!image.aprimo_hero_card_url)
        .forEach((image: ImageData) => {
          const photo = {
            entry: {
              aprimo_hero_card_url: image.aprimo_hero_card_url,
              aprimo_composable_card_url: image.aprimo_composable_card_url,
            },
            src: image.aprimo_hero_card_url,
            title: image.name ?? image.title,
            job_title: image.job_title,
            alt: image.uid,
            width: 300,
            height: 300
          } as HeroImagePhoto
          heroList.push(photo);
        });

      console.debug("heroList", heroList);

      setEntry(heroPage);
      setList(heroList);
      if (skip + limit >= result[1]) {
        setSkip(0);
      } else {
        setSkip(skip + result[0]?.length);
      }
      // entry({ page: heroPage, blogPost: result });
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  

  useEffect(() => {
    // fetchData();
    error && history("/404");
    const timer1 = setInterval(() => fetchData(), 30000)

    return () => {
      clearInterval(timer1)
    }
  }, [error, skip]);

  // if (loading) return <Loader />

  return (
    <>
      {loading && <Loader />}
      {Object.keys(getEntry).length ? (
        <RenderComponents
          pageComponents={getEntry.page_components}
          blogsPage
          contentTypeUid='page'
          entryUid={getEntry.uid}
          locale={getEntry.locale}
        />
      ) : (
        <Skeleton height={400} />
      )}

      {getList.length > 0 ? (
        <div>
          <PhotoAlbum
            layout="rows"
            spacing={5}
            padding={5}
            targetRowHeight={450}
            renderPhoto={renderPhoto}
            photos={getList} />
        </div>
      ) : (
        <div style={{
          maxWidth: "100%",
          minHeight: "100vh",
        }} />
      )}
    </>
  );
}
